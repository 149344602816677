module controllers {
    export module purchase {
        interface IPurchaseCreateScope extends ng.IScope {
        }

        enum EnumCreate {
            CreateNew = 0,
            FromExisting = 1
        }

        export class purchaseOrderCreateCtrl {
            static $inject = ["$scope", '$rootScope', 'generalService', '$q', 'entityService', 'bsLoadingOverlayService', 'purchaseOrderTypeService',
                'supplierService', 'counterService', "$state", "purchaseOrderService", "CreateFromExisting", "$timeout"];
            
            createFrom: EnumCreate;

            entityList: ng.resource.IResourceArray<interfaces.applicationcore.IEntity>;
            ownerEntityId: number;

            purchaseOrderTypeList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;
            purchaseOrderTypeDefer: ng.IDeferred<any>;
            TypeId: number;

            loadPromises: ng.IPromise<any>[];

            supplier: interfaces.applicationcore.IDropdownModel;
            supplierList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;

            POCounterId: number;
            counterTypeDefer: ng.IDeferred<any>;
            POCounterList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;

            customNumberCheck: boolean = false;
            customNumber: string;

            selectedPO: interfaces.applicationcore.IDropdownModel;
            poList: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>;
            
            constructor(private $scope: IPurchaseCreateScope, private $rootScope: interfaces.applicationcore.IRootScope, public generalService: interfaces.applicationcore.IGeneralService,
                private $q: ng.IQService, private entityService: interfaces.applicationcore.IEntityService, private bsLoadingOverlayService,
                private purchaseOrderTypeService: interfaces.purchase.IPurchaseOrderTypeService, private supplierService: interfaces.master.ISupplierService,
                private counterService: interfaces.master.ICounterService, private $state: ng.ui.IStateService,
                private purchaseOrderService: interfaces.purchase.IPurchaseOrderService,
                private CreateFromExisting: boolean,
                private $timeout: ng.ITimeoutService) {
                
                this.createFrom = EnumCreate.CreateNew;

                this.loadPromises = [];
                this.ownerEntityId = $rootScope.Entity.Id;

                this.loadPromises.push(this.loadEntities());
                this.loadPromises.push(this.getCurrentEntity());

                this.purchaseOrderTypeDefer = $q.defer();
                this.loadPromises.push(this.purchaseOrderTypeDefer.promise);

                this.counterTypeDefer = $q.defer();
                this.loadPromises.push(this.counterTypeDefer.promise);

                bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    return $q.all(this.loadPromises).then(() => {
                        this.$timeout(() => {
                            this.loadTypeDefaults(this.POCounterId);
                        });
                    });
                });

            }

            loadEntities() {
                return this.entityService.getList().query((successList) => {
                    this.entityList = successList;
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
            }

            getCurrentEntity() {
                return this.entityService.getCurrentEntity().query((result: interfaces.applicationcore.IEntity) => {
                    this.ownerEntityId = result.Id;
                    this.loadPurchaseOrderTypes();
                    this.loadPOCounter();
                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            ownerEntityChanged() {
                this.supplier = undefined;
                this.TypeId = undefined;
                this.POCounterId = undefined;
                this.selectedPO = undefined;

                this.supplierList = undefined;
                this.POCounterList = undefined;
                this.poList = undefined;

                this.purchaseOrderTypeDefer = this.$q.defer();
                this.loadPurchaseOrderTypes();

                this.counterTypeDefer = this.$q.defer();
                this.loadPOCounter();

                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    return this.purchaseOrderTypeDefer.promise.then(() => {
                        this.$timeout(() => {
                            this.loadTypeDefaults(this.POCounterId);
                        });
                    });
                });
            }

            loadPurchaseOrderTypes() {
                this.purchaseOrderTypeService.getDropdownList().query({ ownerEntityId: this.ownerEntityId }, (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {
                    if (this.purchaseOrderTypeDefer) {
                        this.purchaseOrderTypeDefer.resolve();
                    }

                    this.purchaseOrderTypeList = result;

                    angular.forEach(this.purchaseOrderTypeList, (o) => {
                        if (o.Selected) {
                            this.TypeId = o.Id;
                        }
                    });

                    if (!this.TypeId && this.purchaseOrderTypeList && this.purchaseOrderTypeList.length > 0) {
                        this.TypeId = this.purchaseOrderTypeList[0].Id;
                    }

                }, (failureData) => {
                    if (this.purchaseOrderTypeDefer) {
                        this.purchaseOrderTypeDefer.reject();
                    }
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    this.purchaseOrderTypeList = <ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>>[];
                });
            }

            loadSuppliers(searchText: string) {
                if (this.ownerEntityId) {
                    return this.supplierService.getSupplierDropdown(this.ownerEntityId, searchText).query(() => {

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    }).$promise;
                } else {
                    var defer = this.$q.defer();
                    defer.resolve([]);
                    return defer.promise;
                }
            }

            loadPOCounter() {
                this.counterService.getDropdownList().query({
                    ownerEntityId: this.ownerEntityId,
                    counterTypeId: Enum.EnumCounterTypes.PurchaseOrder
                }, (result: ng.resource.IResourceArray<interfaces.applicationcore.IDropdownModel>) => {

                    this.POCounterList = result;

                    if (this.counterTypeDefer) {
                        this.counterTypeDefer.resolve();
                    }

                    angular.forEach(result, (o) => {
                        if (o.Selected) {
                            this.POCounterId = o.Id;
                        }
                    });

                    }, (failureData) => {

                        if (this.counterTypeDefer) {
                            this.counterTypeDefer.reject();
                        }

                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            loadPurchaseOrders() {
                return this.purchaseOrderService.getDropdownList().query({
                    OwnerEntityId: this.ownerEntityId
                }, () => {

                }, (failureData) => {
                    this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                }).$promise;
            }

            customCounterChanged() {
                this.customNumber = '';
            }

            public selectedPOChanged(model: interfaces.applicationcore.IDropdownModel) {
                if (model) {
                    this.bsLoadingOverlayService.wrap({
                        referenceId: 'create'
                    }, () => {
                        return this.purchaseOrderService.getPurchaseOrder().query({
                            Id: model.Id
                        }, (result: interfaces.purchase.IPurchaseOrder) => {
                            this.supplier = result.Supplier;
                            this.selectedPO = model;
                        }, (failureData) => {
                            this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                        });
                    });
                }
                
            }

            typeChanged() {
                this.loadTypeDefaults(this.TypeId);
            }
            
            loadTypeDefaults(typeId: number) {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    return this.purchaseOrderService.getDefaults(this.ownerEntityId, this.TypeId).get().$promise.then((result) => {

                        if(result.CounterId) {
                            this.POCounterId = result.CounterId;
                        }

                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                });
            }

            close() {
                this.$state.go("^");
            }
            
            create() {
                this.bsLoadingOverlayService.wrap({
                    referenceId: 'create'
                }, () => {
                    return this.createPO().then((result) => {
                        this.generalService.displayMessageHandler(result);
                        this.$state.go("auth.PurchaseOrders.Update", { poId: Number(result.ID) });
                    }, (failureData) => {
                        this.generalService.displayMessageHandler(<interfaces.applicationcore.IMessageHandler>failureData.data);
                    });
                });

                
            }

            createPO() {
                this.customNumber = this.customNumber || '';
               

                if (!this.CreateFromExisting) {
                    return this.purchaseOrderService.create().save({
                        OwnerEntityId: this.ownerEntityId,
                        TypeId: this.TypeId,
                        SupplierId: this.supplier.Id,
                        POCounterId: !this.customNumberCheck ? this.POCounterId : null,
                        poId: this.selectedPO ? this.selectedPO.Id : undefined,
                        CustomNumber: this.customNumber

                    }).$promise;
                }
                else {

                    return this.purchaseOrderService.createExisting().save({
                        OwnerEntityId: this.ownerEntityId,
                        TypeId: this.TypeId,
                        SupplierBranchId: this.supplier.Id,
                        POCounterId: !this.customNumberCheck ? this.POCounterId : null,
                        poId: this.selectedPO ? this.selectedPO.Id : undefined,
                        CustomNumber: this.customNumber

                    }).$promise;
                }
            }
        }

        angular.module("app").controller("purchaseOrderCreateCtrl", controllers.purchase.purchaseOrderCreateCtrl);
    }
}